import './App.css';
import React from 'react';
import { BrowserRouter as Router, Switch, Route, useParams, useLocation } from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCircleNotch,faRandom,faFire,faClock,faBullseye,faTrophy,faVial,faChessKing,faGolfBall,faGlobeAmericas,faBomb,faComments,faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { config } from './firebase';
import { getAuth, onAuthStateChanged } from "firebase/auth";

import Home from './components/Home';
import NavBar from './components/NavBar';
import Admin from './components/Admin';

import Leaderboard from './components/club/Leaderboard';
import LaneRegister from './components/club/LaneRegister';
import LanePay from './components/club/LanePay';
import GameDetail from './components/game/GameDetail';
import UserGameView from './components/game/UserGameView';

import Users from './components/admin/Users';
import UserDetail from './components/admin/UserDetail';
import Putts from './components/admin/Putts';
import Holes from './components/admin/Holes';
import Tournaments from './components/admin/Tournaments';

library.add(faCircleNotch,faRandom,faFire,faClock,faBullseye,faTrophy,faVial,faChessKing,faGolfBall,faGlobeAmericas,faBomb,faComments,faTrashAlt);

firebase.initializeApp(config);

const auth = getAuth();

onAuthStateChanged(auth, (user) => {
  if (user) {
    const uid = user.uid;
  } else {
    console.log("signed out");
  }
});

function RenderRoutes(){

  return(
    <Switch>

      <Route path="/club/register/lane/:laneId">
        <Register />
      </Route>

      <Route path="/club/pay/lane/:laneId">
        <NavBar />
        <Pay />
      </Route>

      <Route path="/leaderboard/:clubId">
        <ClubLeaderboard />
      </Route>

      <Route path="/admin/game/:gameId">
        <NavBar />
        <GameView />
      </Route>

      <Route path="/admin/user/:userId">
        <NavBar />
        <UserView />
      </Route>

      <Route path="/game/:gameId">
        <NavBar />
        <UGameView />
      </Route>


      <Route path="/admin/users">
        <NavBar />
        <Users />
      </Route>

      <Route path="/admin/putts">
        <NavBar />
        <Putts />
      </Route>

      <Route path="/admin/holes">
        <NavBar />
        <Holes />
      </Route>

      <Route path="/admin/tournaments">
        <NavBar />
        <Tournaments />
      </Route>

      <Route path="/admin">
        <NavBar />
        <Admin />
      </Route>

      <Route path="/">
        <NavBar />
        <Home />
      </Route>

    </Switch>
  );
}

function App() {

  return (

    <Router>
      <RenderRoutes />
    </Router>
  );
}

function Register() {
  let { laneId } = useParams();
  return (<LaneRegister laneId={laneId} />);
}

function Pay() {
  let { laneId } = useParams();
  return (<LanePay laneId={laneId} />);
}

function ClubLeaderboard() {
  let { clubId } = useParams();

  var url_string = window.location.href;
  var url = new URL(url_string);
  var s = url.searchParams.get("signage");

  return (<Leaderboard clubId={clubId} signage={s} />);
}

function GameView() {
  let { gameId } = useParams();
  return (<GameDetail gameId={gameId} />);
}

function UserView() {
  let { userId } = useParams();
  return (<UserDetail userId={userId} />);
}
function UGameView() {
  let { gameId } = useParams();
  return (<UserGameView gameId={gameId} />);
}

export default App;

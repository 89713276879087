import React from 'react';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import axios from 'axios';
import { connect } from 'react-redux';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { FirebaseAuthProvider, IfFirebaseAuthed, IfFirebaseUnAuthed } from "@react-firebase/auth";
import { config } from '../../firebase';

import BallPath from '../game/BallPath';

export default class Putts extends React.Component {

  constructor(props){
    super(props);
  }

  state = {
    putts: null
  };

  componentDidMount(){
    this.getPutts();
  }

  getPutts = async() => {
    this.setState({putts:null});

    const url = "https://api.puttr.co/analytics/putts/";
    axios.get(url)
      .then(res => {
        this.setState({ putts: res.data });
      })
  }

  isBall = (ballPath) => {//function to determine if given path is a ball

    //see if ball run under hole
    let max = 0;
    let min = 500;
    console.log("ln:", ballPath.length);
    for (let i = 0; i < ballPath.length; i++) {//get min and max radius
      let rad = ballPath[i][2];
      if (rad > max){
        max = rad;
      }
      if (rad < min){
        min = rad;
      }
    }

    console.log("m-m:" + min + "," + max);

    return [min,max];

    if (max > 60){//check if max between 5 and 30
      console.log("this was not a putt");
      return false;
    }
    console.log("this was a putt");
    return true;

  }

  renderPutts = () => {

    if (this.state.putts){

      return this.state.putts.map((putt, index) => {

        const isb = this.isBall(JSON.parse(putt.ballPath));

        let t = "LEFT";
        if (putt.tee == 2)
          t = "CENTER";
        else if (putt.tee == 3)
          t = "RIGHT";

        return (

          <div key={index}>

            <div style={{width:'320px', marginRight:'5px'}}
              onClick={() => {this.greenPressed(putt.ballPath)}}
            >

              <BallPath ballPath={putt.ballPath} made={putt.made} width={320} />

            </div>

            <div style={{alignItems:'center', justifyContent:'center'}}>

              <b>{putt.distance}</b>  feet, <b>{t}</b>  tee

              IsBall: {isb[0] + "," + isb[1]}

            </div>

          </div>

        );

      });
    }
  }

  render(){

    return(

      <Container fluid className="align-items-center justify-content-center">

        <FirebaseAuthProvider firebase={firebase} {...config}>
          <div style={{ marginTop:'80px'}}>

            <IfFirebaseAuthed>
            {({ isSignedIn, user, providerId }) => {
              return (

                <div className="heading" style={{marginTop:'80px'}}>

                  <h2>PUTTS</h2>

                  <div style={{marginTop:'20px'}} className="row">

                    {this.renderPutts()}

                  </div>

                </div>
              );
            }}
            </IfFirebaseAuthed>

          </div>
        </FirebaseAuthProvider>

      </Container>

    );

  }

}

import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';

let mult = 1;//multiplier for ratio of green view

class BallPath extends Component {

  constructor(props){
    super(props);
  }

  renderBallPath(){

    //let stroke = "rgb(255,255,255)";

    let stroke = "rgb(139,0,0)";
    //let fill = "rgb(0,0,0)";
    let fill = "rgb(5,50,32)";

    console.log("putt props:", this.props.putt.made);

    if (this.props.putt.made){
      //fill = "rgb(5,50,32)";
      fill = "#1ea51e";
      //fill = "rgb(255,255,255)";
    }

    let w = this.props.width;
    mult = w / 640;

    const gw = 640 * mult;
    const gh = 420 * mult;


    let holeX = gw / 2;
    let holeY = 80 * mult;
    let holeRad = 65 * mult;

    if (this.props.putt.holeBounds){
      let hb = JSON.parse(this.props.putt.holeBounds);
      holeX = hb[0] * mult;
      holeY = hb[1] * mult;
      holeRad = (hb[2] / 2) * mult;
    }

    return(

      <div className="ballPath" style={{backgroundColor:fill}}>

      <svg height={gh} width={gw}>

        <rect
          x="0"
          y="0"
          width={gw}
          height={gh}
          fill={fill}
        />

        <circle cx={holeX} cy={holeY} r={holeRad} fill='#000' stroke='#333' />

        {this.renderPoints(stroke)}

      </svg>

      </div>

    );

  }

  renderPoints(){

    var url_string = window.location.href;
    var url = new URL(url_string);
    var s = url.searchParams.get("showRad");

    if (this.props.showRad){
      s = 1;
    }

    if (this.props.putt.ballPath){

      var bp = JSON.parse(this.props.putt.ballPath);

      return bp.map((item, index) => {
        const x = item[0] * mult;
        const y = item[1] * mult;

        let ballSize = 20 * mult;
        if (s){
          ballSize = item[2] * mult;
        }

        return(

          <circle cx={x} cy={y} r={ballSize} fillOpacity='.1' fill='#fff' stroke='#fff' key={index} />

        );

      });
    }
  }

  render(){

    return(

      <div style={{alignItems:'center'}}>

        { this.renderBallPath() }

      </div>

    );

  }

}


export default BallPath;
